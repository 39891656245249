import React from "react";
import { GiMegaphone } from "react-icons/gi";

const ComingSoon = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex  items-center justify-center">
      <div className="bg-transparent md:bg-white p-3 md:p-8 rounded-lg flex flex-col space-y-3 md:shadow-md text-center">
        <GiMegaphone className="w-20 md:w-40 h-20 md:h-40 mx-auto text-secondary-dark" />
        <h1  className="text-3xl font-semibold">
          Coming Soon!
        </h1>
        <p >
          Our awesome website  is under construction. Stay tuned for updates!
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
